<template>
  <div v-if="model.homeTeam">
    <div class="p-2">
      <button class="btn btn-sm btn-primary" @click="$refs.embedVideo.open()"><i class="fa fa-video-camera"></i></button>
      <le-copy-to-clipboard icon="fa fa-television" class="ml-2" :text="getGameBroadcastWebsiteUrl(model)"></le-copy-to-clipboard>
    </div>
    <h1 class="text-center display-3">PRE GAME</h1>
    <div class="row">
      <div class="col-xl-5 col-md-6 offset-xl-1">
        <button v-if="config.pre_game.add_players" class="btn btn-sm btn-success w-100" @click="playerCreateModal(model.homeTeam.uuid)">{{$t('player.add')}}</button>
        <ntm-block v-if="model.homeTeam" :title="model.homeTeam.name">
          <template #options>
            <button class="btn-no-style debt-button" v-if="model.homeTeam.debts.length" @click="openDebts('team', model.homeTeam)"><i class="fa fa-usd text-warning"></i></button>
          </template>
          <div class="team-color d-flex justify-content-between align-items-center">
            <span>{{$tc('team.color', 1)}}:</span>
            <v-swatches
              v-model="homeTeamColor"
              :swatches="colorOptions"
              popover-x="left"
            ></v-swatches>
          </div>
          <table v-if="model.homeTeam && model.homeTeam.players.length > 0" class="table player-live-table table-hover table-vcenter font-size-sm">
            <thead>
            <tr>
              <th class="number-of-jersey-suggestion"></th>
              <th class="text-center">{{$t('pre_game.table.jersey')}}</th>
              <th>{{$t('pre_game.table.name')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(player, n) in model.homeTeam.players" :key="player.uuid">
              <td class="d-sm-table-cell">
                <button @click="updateNumberOfJersey('homeTeam', n, player.number_of_jersey_suggestion)" v-if="!player.number_of_jersey && player.number_of_jersey_suggestion" class="btn btn-sm btn-info">
                  {{player.number_of_jersey_suggestion}}
                </button>
                <button @click="removeNumberOfJersey('homeTeam', n)" v-if="player.number_of_jersey" class="btn btn-sm btn-warning">
                  <i class="fa fa-times"></i>
                </button>
              </td>
              <td class="d-sm-table-cell font-w600 text-center number-of-jersey-column" style="font-size: 18px">
                <input class="form-control number-of-jersey-input" v-model="model.homeTeam.players[n].number_of_jersey"  type="number"></td>
              <td class="d-sm-table-cell font-w600 pl-2">{{player.name}} <button class="btn-no-style debt-button" v-if="model.homeTeam.players[n].debts.length" @click="openDebts('player', model.homeTeam.players[n])"><i class="fa fa-usd text-warning"></i></button></td>
              <td class="d-sm-table-cell column-img font-w600" @click="updatePlayerImage(player)"><img v-if="player.profile" :src="player.profile" alt="" class="img-avatar img-avatar32"><img v-else src="/empty-player.jpg" alt="" class="img-avatar img-avatar32"></td>
            </tr>
            </tbody>
          </table>
          <div v-if="homeErrors.length > 0" class="text-danger">
            <p><strong>{{$tc('errors.name', 2)}}:</strong></p>
            <ul>
              <li v-for="error in homeErrors" :key="error">{{showError(error)}}</li>
            </ul>
          </div>
        </ntm-block>
        <v-select v-if="config.pre_game.add_players" class="mb-4" @search="searchPlayer" :filter="(options) => options" @option:selected="(e) => selectPlayer(e, model.homeTeam.uuid)"
                  v-model="addSelectedPlayer" :options="playerFilteredOptions" inputId="homeTeamPlayerSearch" label="name"
                  :placeholder="$tc('player.searchAndAdd')">
          <template #option="option">
            {{ option.name }}
          </template>
          <template #no-options>
            <p class="info">{{ $tc('player.searchAndAdd') }}</p>
          </template>
        </v-select>
      </div>
      <div class="col-xl-5 col-md-6">
        <button v-if="config.pre_game.add_players" class="btn btn-sm btn-success w-100" @click="playerCreateModal(model.awayTeam.uuid)">{{$t('player.add')}}</button>
        <ntm-block v-if="model.awayTeam" :title="model.awayTeam.name">
          <template #options>
            <button class="btn-no-style debt-button" v-if="model.awayTeam.debts.length" @click="openDebts('team', model.awayTeam)"><i class="fa fa-usd text-warning"></i></button>
          </template>
          <div class="team-color d-flex justify-content-between align-items-center">
            <span>{{$tc('team.color', 1)}}:</span>
            <v-swatches
              v-model="awayTeamColor"
              popover-x="left"
              :swatches="colorOptions"
            ></v-swatches>
          </div>
          <table v-if="model.awayTeam && model.awayTeam.players.length > 0" class="table player-live-table table-hover table-vcenter font-size-sm">
            <thead>
            <tr>
              <th class="number-of-jersey-suggestion"></th>
              <th class="text-center">{{$t('pre_game.table.jersey')}}</th>
              <th>{{$t('pre_game.table.name')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(player, n) in model.awayTeam.players" :key="player.uuid">
              <td class="d-sm-table-cell">
                <button @click="updateNumberOfJersey('awayTeam', n, player.number_of_jersey_suggestion)" v-if="!player.number_of_jersey && player.number_of_jersey_suggestion" class="btn btn-sm btn-info">
                  {{player.number_of_jersey_suggestion}}
                </button>
                <button @click="removeNumberOfJersey('awayTeam', n)" v-if="player.number_of_jersey" class="btn btn-sm btn-warning">
                  <i class="fa fa-times"></i>
                </button>
              </td>
              <td class="d-sm-table-cell font-w600 text-center number-of-jersey-column" style="font-size: 18px">
                <input class="form-control number-of-jersey-input" v-model="model.awayTeam.players[n].number_of_jersey" type="number"></td>
              <td class="d-sm-table-cell font-w600 pl-2">{{player.name}} <button class="btn-no-style debt-button" v-if="model.awayTeam.players[n].debts.length" @click="openDebts('player', model.awayTeam.players[n])"><i class="fa fa-usd text-warning"></i></button></td>
              <td class="d-sm-table-cell column-img font-w600" @click="updatePlayerImage(player)"><img v-if="player.profile" :src="player.profile" alt="" class="img-avatar img-avatar32"><img v-else src="/empty-player.jpg" alt="" class="img-avatar img-avatar32"></td>
            </tr>
            </tbody>
          </table>
          <div v-if="awayErrors.length > 0" class="text-danger">
            <p><strong>{{$tc('errors.name', 2)}}:</strong></p>
            <ul>
              <li v-for="error in awayErrors" :key="error">{{showError(error)}}</li>
            </ul>
          </div>
        </ntm-block>
        <v-select v-if="config.pre_game.add_players" class="mb-4" @search="searchPlayer" :filter="(options) => options" @option:selected="(e) => selectPlayer(e, model.awayTeam.uuid)"
                  v-model="addSelectedPlayer" :options="playerFilteredOptions" inputId="homeTeamPlayerSearch" label="name"
                  :placeholder="$tc('player.searchAndAdd')">
          <template #option="option">
            {{ option.name }}
          </template>
          <template #no-options>
            <p class="info">{{ $tc('player.searchAndAdd') }}</p>
          </template>
        </v-select>
      </div>
    </div>
    <div class="row text-center mt-3 mb-5">
      <div class="col-sm-12 text-center">
        <button class="btn btn-success btn-lg" :disabled="goLiveIsLoading" @click="goLive()">
          <i v-if="goLiveIsLoading" class="fa fa-cog fa-spin"></i>
          <span v-if="!goLiveIsLoading">Go live</span>
        </button>
      </div>
    </div>
    <ntm-modal @closed="selectedPlayer = null" size="lg" ref="updatePlayerImageModal">
      <ntm-crop :key="selectedPlayer.uuid" v-if="selectedPlayer" @change="updatePlayerProfile"  entity-type="player" :entity-uuid="selectedPlayer.uuid" collection="profile" :circle="true"></ntm-crop>
    </ntm-modal>

    <ntm-modal size="lg" ref="embedVideo">
      <form id="embedVideo" @submit.prevent="onEmbedVideoSubmit()">
        <formly-form :form="embedVideoForm" :model="embedVideoModel" :fields="embedVideoFields"></formly-form>
        <button class="btn btn-success push-15">{{ $t('save') }}</button>
      </form>

    </ntm-modal>

    <ntm-sidebar @close="teamUuid = null" :title="$t('player.create')" name="add-player">
      <form id="playerForm" @submit.prevent="onPlayerSubmit()">
        <formly-form :form="playerForm" :model="playerModel" :fields="playerFields"></formly-form>
        <button class="btn btn-success push-15">{{ $t('save') }}</button>
      </form>
    </ntm-sidebar>
    <modal name="debts-modal" height="auto">
      <div class="p-2">
        <h3 class="text-center">{{debtEntity?.name}}</h3>
        <debt class="mb-3" v-for="debt in debtEntity?.debts" :debt="debt" :entity="debtEntity" @pay="onPay" :key="debt.uuid"></debt>
      </div>
    </modal>
  </div>
</template>

<script>
import GameService from '@/services/game.service'
import router from '@/router'
import playerFields from '@/formDefinitions/player'
import embedVideoFields from '@/formDefinitions/embedVideo'
import { useRouteQuery } from '@/use/useRouteQuery'
import _ from 'lodash'
import TeamService from '../services/team.service'
import GlobalService from '../services/global.service'
import VueI18n from '../i18n'
import { useExternalLinks } from '@/use/useExternalLinks'
import VSwatches from 'vue-swatches'
import { ToastMessage } from '@/lib/toast'
export default {
  name: 'preGame',
  components: { VSwatches },
  setup () {
    const { getGameBroadcastWebsiteUrl } = useExternalLinks()
    const addPlayer = useRouteQuery('add-player')
    const teamUuid = useRouteQuery('team')
    return {
      addPlayer,
      teamUuid,
      getGameBroadcastWebsiteUrl
    }
  },
  data () {
    return {
      colorOptions: [
        '#2ECC70',
        '#27AF60',
        '#3398DB',
        '#2980B9',
        '#AE80C2',
        '#A463BF',
        '#FDD01C',
        '#ECA713',
        '#BA8A21',
        '#E38A82',
        '#EF5A4D',
        '#CCCCCC'
      ],
      homeTeamColor: localStorage.getItem('homeTeamColor') ?? '#70b9eb',
      awayTeamColor: localStorage.getItem('awayTeamColor') ?? '#70b9eb',
      debtEntityType: null,
      debtEntity: null,
      goLiveIsLoading: false,
      debts: [],
      model: {},
      homeErrors: [],
      awayErrors: [],
      playerOptions: [],
      selectedPlayer: null,
      addSelectedPlayer: null,
      playerFormTeamUuid: null,
      playerForm: {},
      playerModel: {
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        birthYear: ''
      },
      playerFields: playerFields,
      embedVideoForm: {},
      embedVideoModel: {
        code: ''
      },
      embedVideoFields: embedVideoFields
    }
  },
  beforeRouteEnter (to, from, next) {
    GameService.status().then((response) => {
      if (response.data === 'FINISHED') {
        router.push({ name: 'archive', params: { clientId: to.params.clientId, code: to.params.code } })
      } else {
        GameService.preGame().then((response) => {
          next(vm => vm.setData(response.data))
        })
      }
    })
  },
  methods: {
    onPay (data) {
      this.$swal.fire({
        title: data.entity.name,
        text: VueI18n.t('swal.payDebt.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: VueI18n.t('swal.payDebt.yes'),
        cancelButtonText: VueI18n.t('swal.payDebt.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          GameService.payDebt(data.debt.uuid).then(() => {
            if (this.debtEntityType === 'player') {
              const player = this.model.homeTeam.players.find(p => p.uuid === data.entity.uuid) ?? this.model.awayTeam.players.find(p => p.uuid === data.entity.uuid)

              const debtIndex = player.debts.findIndex(d => d.uuid === data.debt.uuid)

              player.debts.splice(debtIndex, 1)

              if (!player.debts.length) {
                this.$modal.hide('debts-modal')
              }
            }
            if (this.debtEntityType === 'team') {
              const team = this.model.homeTeam.uuid === data.entity.uuid ? this.model.homeTeam : this.model.awayTeam

              const debtIndex = team.debts.findIndex(d => d.uuid === data.debt.uuid)

              team.debts.splice(debtIndex, 1)

              if (!team.debts.length) {
                this.$modal.hide('debts-modal')
              }
            }
          })
        }
      })
    },
    openDebts (entity, entityModel) {
      this.debtEntityType = entity
      this.debtEntity = entityModel
      this.$modal.show('debts-modal')
    },
    searchPlayer (search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchGlobalPlayers(search, loading)
      } else {
        this.playerOptions = []
      }
    },
    focusAddNewPlayerFirstField () {
      setTimeout(() => {
        const playerFormFirstName = document.querySelector('#playerForm .form-control')
        playerFormFirstName.focus()
      })
    },
    selectPlayer (player, team) {
      this.addSelectedPlayer = null
      if (player.uuid === 'add') {
        this.playerCreateModal(team)
        return
      }
      TeamService.addPlayerToTeam(team, { playerUuid: player.uuid }).then((res) => {
        this.pushPlayerToTeam(res.data, team)
      }).catch(() => {
        ToastMessage({
          title: this.$t('player.in_another_team'),
          icon: 'error'
        })
      }).finally(() => {
        this.addSelectedPlayer = null
      })
    },
    searchGlobalPlayers: _.debounce(function (search, loading) {
      GlobalService.searchPlayers(search).then((res) => {
        this.playerOptions = res.data
        if (!this.playerFilteredOptions.length) {
          this.playerOptions.push({ name: this.$t('player.add'), uuid: 'add' })
        }
        loading(false)
      })
    }, 300),
    playerCreateModal (teamUuid) {
      if (!this.config.pre_game.add_players) return
      this.addPlayer = true
      this.teamUuid = teamUuid
      this.focusAddNewPlayerFirstField()
    },
    pushPlayerToTeam (player, teamUuid) {
      if (this.model.homeTeam.uuid === teamUuid) {
        this.model.homeTeam.players.push(player)
      }
      if (this.model.awayTeam.uuid === teamUuid) {
        this.model.awayTeam.players.push(player)
      }
    },
    onPlayerSubmit () {
      this.playerForm.$errors = {}
      TeamService.createAndAddNewPlayerToTeam(this.teamUuid, this.playerModel).then((res) => {
        this.pushPlayerToTeam(res.data, this.teamUuid)

        this.addPlayer = null
        this.teamUuid = null
        this.playerModel = {
          firstName: '',
          lastName: '',
          middleName: '',
          email: '',
          birthYear: ''
        }
      }).catch((err) => {
        this.playerForm.$errors = err.response.data.errors
      })
    },
    onEmbedVideoSubmit () {
      GameService.embedVideo(this.embedVideoModel).then(() => {
        this.model.embedVideoCode = this.embedVideoModel.code
        this.$refs.embedVideo.close()
      })
    },
    updatePlayerImage (player) {
      this.selectedPlayer = player
      this.$refs.updatePlayerImageModal.open()
    },
    updatePlayerProfile (imageUrl) {
      if (!this.config.pre_game.upload_images) return
      const homePlayer = this.model.homeTeam.players.find((player) => player.uuid === this.selectedPlayer.uuid)
      if (homePlayer) {
        homePlayer.profile = imageUrl
      }

      const awayPlayer = this.model.awayTeam.players.find((player) => player.uuid === this.selectedPlayer.uuid)
      if (awayPlayer) {
        awayPlayer.profile = imageUrl
      }

      this.$refs.updatePlayerImageModal.close()
    },
    async goLive () {
      this.goLiveIsLoading = true
      GameService.goLive(this.model).then(() => {
        this.homeErrors = []
        this.awayErrors = []
        if (this.model.status === 'SCHEDULED' && this.model.embedVideoCode) {
          this.$swal.fire({
            title: `<strong>${this.$t('livestream.swal.check.title')}</strong>`,
            icon: 'info',
            html: this.model.embedVideoCode,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: `
            <i class="fa fa-thumbs-up"></i> ${this.$t('livestream.swal.check.confirm')}
          `,
            confirmButtonAriaLabel: 'Livestream is ready!'
          }).then((result) => {
            if (result.value) {
              router.push({ name: 'live' })
            }
          }).finally(() => {
            this.goLiveIsLoading = false
          })
        } else {
          router.push({ name: 'live' })
        }
      }).catch((err) => {
        this.homeErrors = []
        this.awayErrors = []
        Object.keys(err.response.data.errors).forEach((key) => {
          if (key.includes('homeTeam')) {
            err.response.data.errors[key].forEach((error) => {
              this.homeErrors.push(error)
            })
          } else if (key.includes('awayTeam')) {
            err.response.data.errors[key].forEach((error) => {
              this.awayErrors.push(error)
            })
          }
        })

        this.goLiveIsLoading = false
      })
    },
    showError (error) {
      const arrErrorTypes = error.split('.')
      const errorType = arrErrorTypes[arrErrorTypes.length - 1]
      const errorTypeArray = errorType.split('|')
      const errorTypeName = errorTypeArray.shift()

      return this.$t('errors.live.preGame.' + errorTypeName, errorTypeArray)
    },
    setData (data) {
      this.model = data
      this.embedVideoModel.code = data.embedVideoCode
    },
    updateNumberOfJersey (team, playerIndex, numberOfJersey) {
      this.model[team].players[playerIndex].number_of_jersey = numberOfJersey
    },
    removeNumberOfJersey (team, playerIndex) {
      this.model[team].players[playerIndex].number_of_jersey = null
    }
  },
  watch: {
    awayTeamColor (val) {
      localStorage.setItem('awayTeamColor', val)
    },
    homeTeamColor (val) {
      localStorage.setItem('homeTeamColor', val)
    }
  },
  computed: {
    homePlayersUuids () {
      return this.model.homeTeam.players.map(player => player.uuid)
    },
    awayPlayersUuids () {
      return this.model.awayTeam.players.map(player => player.uuid)
    },
    playerFilteredOptions () {
      return this.playerOptions.filter((player) => {
        const mergePlayers = this.homePlayersUuids.concat(this.awayPlayersUuids)
        return !mergePlayers.includes(player.uuid)
      })
    },
    config () {
      return this.$store.state.config
    }
  }
}

</script>

<style lang="scss">
.btn-success {
  background-color: #00B5ACFF !important;
}

.number-of-jersey-suggestion {
  width: 40px;
}

.punishment-cell {
  width: 40px;
}

.number-of-jersey-column {
  width: 65px;
}

.form-control.number-of-jersey-input {
  text-align: center;
  width: 65px;
  margin: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.debt-button i {
  font-size: 16px;
}

.team-color {
  padding: 5px 0;
  .vue-swatches .vue-swatches__trigger__wrapper {
    display: block;
  }
  span {
    font-weight: bold;
  }
}
</style>
