<template>
  <!-- Sign In Block -->
  <div class="login-wrapper block mb-0 position-relative">
    <div class="vertical-logo">
      <vertical-logo />
    </div>
    <div class="block-header">
      <h3 class="block-title">Sign In</h3>
    </div>
    <div class="block-content">
      <div class="p-sm-3 px-lg-4 py-lg-4">

        <!-- Sign In Form -->
        <!-- jQuery Validation (.js-validation-signin class is initialized in js/pages/op_auth_signin.min.js which was auto compiled from _es6/pages/op_auth_signin.js) -->
        <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
        <form @submit.prevent="handleSubmit">
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <span v-if="authenticationErrorCode === 400" class="help-block text-danger d-block mb-2">{{ $t('email-password.notValid') }}</span>
          <button type="submit" class="btn btn-block btn-primary">
            <i v-if="!authenticating" class="fa fa-fw fa-sign-in-alt mr-1"></i> <i v-if="authenticating" class="fa fa-fw fa-cog fa-spin"></i> {{ $t('sign-in') }}
          </button>
        </form>
        <!-- END Sign In Form -->
        <div class="text-center">
          <a class="btn-block-option font-size-sm" href="https://leaguengine.io/auth/forgot-password" target="_blank">{{$t('forgotPassword')}}</a>
        </div>
      </div>
    </div>
  </div>
  <!-- END Sign In Block -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VerticalLogo from '@/components/svg/VerticalLogo.vue'
export default {
  name: 'login',
  components: { VerticalLogo },
  data () {
    return {
      model: {
        email: '',
        password: ''
      },
      form: {},
      fields: [
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            inputType: 'email',
            label: 'email'
          }
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            inputType: 'password',
            label: 'password'
          }
        }
      ]
    }
  },
  computed: {
    forgotPasswordUrl () {
      return process.env.VUE_APP_OFFICIAL_WEBSITE_URL + '/auth/forgot-password'
    },
    ...mapGetters('auth', [
      'authenticating',
      'authenticationError',
      'authenticationErrorCode'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    handleSubmit () {
      // Perform a simple validation that email and password have been typed in
      if (this.model.email !== '' && this.model.password !== '') {
        this.login({ email: this.model.email, password: this.model.password })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-wrapper {
  filter: drop-shadow(0px 0px 10px rgba(#0B2C36FF, 0.1));
  border-radius: 8px;
}
.vertical-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 100%;
  padding: 24px;
  color: #0B2C36FF;

  svg {
    height: 80px;
    width: 80px;
  }
}
a {
  color: #0B2C36FF;
  transition: 0.15s;
  &:hover {
    color: #0B2C36FF;
    opacity: 0.7;
  }
}
</style>
